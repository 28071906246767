import { SearchCollectionResponse } from 'schools-domain-backend-utils'
import { AgencyApplicationResponseResource, ApplicationSortableFields } from "@backend/resources/application";
import { GetApplicationsPagePropsResource } from '@backend/agents-ui-api/routes/applications/page-props/GET/types'

export type Application = AgencyApplicationResponseResource
export type RawApplicationsResponse = SearchCollectionResponse<Application>
export type ApplicationsPageProps = GetApplicationsPagePropsResource

export type GetApplicationsQueryKey = keyof GetApplicationsQuerys;
export type GetApplicationsQuerys = {
  searchQuery: string | undefined;
  status: ApplicationState[];
  nationality: string[];
  programId: Array<string>;
  intakeTermId: Array<string>;
  submissionDate: { to?: string; from?: string };
  pageSize: number;
  pageNumber: number;
  sort: SortableFields | undefined;
};

export type GetApplicationSearchQuery = {
  'filter[q]'?: string
  'filter[status]'?: ApplicationState[]
  'filter[nationality]'?: string[]
  'filter[programId]'?: string[]
  'filter[intakeTermId]'?: string[]
  'filter[submittedAt][gt]'?: string
  'filter[submittedAt][lt]'?: string
  'page[size]': number
  'page[number]': number
  sort?: ApplicationSortableFields
};

export type SortableFields = ApplicationSortableFields
export enum ApplicationState {
  DRAFT = 'DRAFT',
  PAYMENT_PROCESSING = 'PAYMENT_PROCESSING',
  READY_FOR_SCREENING = 'READY_FOR_SCREENING',
  READY_FOR_ASSESSMENT = 'READY_FOR_ASSESSMENT',
  INITIAL_OFFER = 'INITIAL_OFFER',
  DECLINED = 'DECLINED',
  DOCUMENTS_REQUESTED_IN_SCREENING = 'DOCUMENTS_REQUESTED_IN_SCREENING',
  DOCUMENTS_REQUESTED_IN_ASSESSMENT = 'DOCUMENTS_REQUESTED_IN_ASSESSMENT',
  DEFERRAL_REQUESTED_IN_SCREENING = 'DEFERRAL_REQUESTED_IN_SCREENING',
  DEFERRAL_REQUESTED_IN_ASSESSMENT = 'DEFERRAL_REQUESTED_IN_ASSESSMENT',
  DEFERRAL_REQUESTED_IN_INITIAL_OFFER = 'DEFERRAL_REQUESTED_IN_INITIAL_OFFER',
}
